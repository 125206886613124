// vuexPersist.js
export default (store) => {
  // Load state from localStorage
  const savedState = localStorage.getItem('vuex');
  if (savedState) {
    store.replaceState(Object.assign({}, store.state, JSON.parse(savedState)));
  }

  // Subscribe to store updates
  store.subscribe((mutation, state) => {
    localStorage.setItem('vuex', JSON.stringify(state));
  });
};