<template>
    <div>
      <h1>404 - Page Not Found</h1>
      <router-link to="/">Go to Home</router-link>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NotFound',
    mounted() {
      console.log('NotFound component rendered');
    },
  };
  </script>
  
  <style scoped>
  /* Add any necessary styles */
  </style>
  