<template>
    <div class="message-block d-flex align-items-center p-3" :class="{'w-100': !isBot}">
      <div class="icon-container me-3" v-if="!isBot">
        <img class="logo-icon" src="@/assets/logo.png" alt="">
      </div>
      <div class="message-content flex-grow-1">
        <div class="message-header d-flex align-items-center mb-1">
          <strong class="message-sender me-2">{{ sender }}</strong>
          <span class="dot-container">
            <span class="dot"></span>
        </span>
          <span class="timestamp text-muted">{{ formattedDate }}</span>
        </div>
        <div class="message-text">
          {{ message }}
        </div>
      </div>
      <div class="message-actions ms-3 d-flex align-items-center p-2 border rounded" v-if="!isBot">
        <font-awesome-icon 
            :icon="likeIcon" 
            :class="{ 'text-success': isLiked }" 
            @click="toggleLike" 
            class="me-2 action-icon"
            />                
        <font-awesome-icon 
            :icon="dislikeIcon" 
            :class="{ 'text-danger': isDisliked }" 
            @click="toggleDislike" 
            class="me-2 action-icon"
            />
      </div>
    </div>
  </template>
  
  <script>
  import { ref, computed,  onMounted, onBeforeUnmount } from 'vue';
  import { formatDistanceToNow } from 'date-fns';


  export default {
    props: {
      message: String,
      sender: String,
      isBot: Boolean,
      createdAt: String
    },
    setup(props) {
        const isLiked = ref(false);
        const isDisliked = ref(false);

        const toggleLike = () => {
            isLiked.value = !isLiked.value;
            if (isLiked.value) {
                isDisliked.value = false;
            }
        };

        const toggleDislike = () => {
            isDisliked.value = !isDisliked.value;
            if (isDisliked.value) {
                isLiked.value = false;
            }
        };

        const likeIcon = computed(() => (isLiked.value ? ['fas', 'thumbs-up'] : ['far', 'thumbs-up']));
        const dislikeIcon = computed(() => (isDisliked.value ? ['fas', 'thumbs-down'] : ['far', 'thumbs-down']));
      
        // Function to format the date
        const getFormattedDate = () => {
          return formatDistanceToNow(new Date(props.createdAt), { addSuffix: true });
        };

        const formattedDate = ref(getFormattedDate());
        let interval;
        onMounted(() => {
          interval = setInterval(() => {
            formattedDate.value = getFormattedDate();
          }, 60000);
        });
        onBeforeUnmount(() => {
          clearInterval(interval);
        });

        return {
            likeIcon,
            dislikeIcon,
            isLiked,
            isDisliked,
            toggleLike,
            toggleDislike,
            formattedDate
        };
    }
  };
  </script>
  
  <style scoped>
  
  .message-icon {
    font-size: 24px;
    color: #6c757d; /* Icon color */
  }
  
  .message-header {
    font-size: 12px;
    color: #495057;
  }
  
  .timestamp {
    font-size: 12px;
  }
  
  .message-text {
    font-size: 14px;
    color: #212529;
  }
  
  .action-icon {
    font-size: 18px;
    color: #6c757d;
    cursor: pointer;
  }

    .dot-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
    }
    .dot {
        width: 4px;
        height: 4px;
        background-color: black;
        border-radius: 50%;
    }
  </style>
  