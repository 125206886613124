<template>
    <button
        class="prompt-button col tiny-font fw-semibold w-100"
        @click="handleClick"
    >{{ text }}</button>
  </template>
  
  <script>
  export default {
    name: 'PromptButton',
    props: {
      text: String
    },
    methods: {
        handleClick() {
            this.$emit('prompt-clicked', this.text);
        }
    }
  }
  </script>
  
  <style scoped>
  .prompt-button {
    background-color: transparent;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 100%;
  }
  .prompt-button:hover {
    background-color: #f0f0f0;
  }
  </style>
  