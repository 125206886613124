<template>
    <div class="sidebar justify-content-between">
      <div class="sidebar-header text-center">
        <div class="sidebar-logo mb-3">
          <router-link to="/">
            <img class="logo-icon" src="@/assets/logo.png" alt="">
          </router-link>
        </div>
        <div class="dotted-line mb-3"></div>
        <div class="mt-2">
          <!-- <router-link to="/chat"> -->
            <font-awesome-icon :icon="['fas', 'magnifying-glass']" :class="['sidebar-icon']"/>
          <!-- </router-link> -->
        </div>
        <div class="mt-2">
          <!-- <router-link :to="{ name: 'ChatView', query: { loadHistory: true } }"> -->
            <font-awesome-icon :icon="['far', 'comments']" :class="['sidebar-icon']"/>
          <!-- </router-link> -->
        </div>
        <div class="mt-2">
          <font-awesome-icon :icon="['far', 'folder']" :class="['sidebar-icon']"/>
        </div>
        <div class="dotted-line"></div>
      </div>
      <div class="sidebar-footer">
        <div class="text-center mb-3">
          <div class="mb-2">
            <!-- <font-awesome-icon :icon="['fas', 'gear']" :class="['sidebar-icon']"/> -->
          </div>
          <div class="mb-2" v-if="isAuthenticated">
            <font-awesome-icon @click="logout" :icon="['fas', 'power-off']" :class="['custom-pointer']"/>
          </div>
        </div>
        <div class="dotted-line mb-3"></div>
        <div class="sidebar-logo mb-3">
          <img class="logo-icon" src="@/assets/man.png" alt="">
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';

  export default {
    data() {
      return {
        icons: [
          { class: 'fa fa-home', label: 'Home' },
          { class: 'fa fa-search', label: 'Search' },
          { class: 'fa fa-bell', label: 'Notifications' },
          { class: 'fa fa-cog', label: 'Settings' }
        ]
      };
    },
    setup() {
      const store = useStore();

      const user = computed(() => store.state.user);
      const isAuthenticated = computed(() => store.state.isAuthenticated);

      const logout = () => {
        store.dispatch('logout');
      };

      return { isAuthenticated, user, logout };
    }
  };
  </script>
  
  <style scoped>
  .sidebar {
    width: 70px;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-right: 0px;
    height: 100vh;
    border-right: 1.5px solid lightgrey;
  }
  .sidebar-icon {
    height: 14px;
    color: #000;
  }

  
  .dotted-line {
    margin-top: 20px;
    border-bottom: 1.5px dotted #c4bcbc;
    width: 100%;
  }
  </style>
  