<template>
  <GoogleLogin/>
  <div class="row w-100">
    <SidenavComponent/>
    <div class="col row ">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import SidenavComponent from '@/components/layout/SidenavComponent.vue';
  import GoogleLogin from '@/components/layout/GoogleLogin.vue';

  export default {
    name: 'EzyUni',
    components: {
      SidenavComponent,
      GoogleLogin
    }
  }
</script>

<style>
  html, body {
    margin: 0;
    height: 100%;
  }

  #app {
    display: flex;
    height: 100vh;
    background-color: #EEEEEE;
  }

  .small-font {
    font-size: 0.875rem !important; /* 14px */
  }

  .smaller-font {
    font-size: 0.75rem !important; /* 12px */
  }

  .tiny-font {
    font-size: 0.625rem !important; /* 10px */
  }

  .light-font {
    font-weight: 400;
    color: #a7a1a1 !important;
  }

  .no-word-spacing {
    word-spacing: -2px;
  }


  .logo-icon {
    height: 24px;
    width: 24px;
  }
</style>
