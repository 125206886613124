<template>
    <div>
    </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';

export default {
    mounted() {
        this.initializeGoogle();
    },
    setup() {
      const store = useStore();

      const user = computed(() => store.state.user);
      const isAuthenticated = computed(() => store.state.isAuthenticated);
      const accessToken = computed(() => store.state.accessToken);
      const userName = computed(() => store.state.userName);

      const login = (user, accessToken) => {
        store.dispatch('login', { user, accessToken });
      };

      const setName = (userName) => {
        store.dispatch('userName', { userName });
      };

      return { isAuthenticated, user, login, setName, accessToken, userName };
    },
    data() {
      return {
        apiResponse: null,
        apiError: null,
        sessionToken: null
      };
    },
    methods: {
        initializeGoogle() {
            if (window.google && google.accounts && google.accounts.id) {
                google.accounts.id.initialize({
                    client_id: '1018713702828-atbnm7u5l8r7v6r7kmurbnplmvtqh6ri.apps.googleusercontent.com',
                    callback: this.handleCredentialResponse,
                });
                if (!this.isAuthenticated) {
                    this.loginWithGoogle()
                }
            }
            else {
                setTimeout(this.initializeGoogle, 500);
            }
        },
        loginWithGoogle() {
            google.accounts.id.prompt();
        },
        async handleCredentialResponse(response) {
            try {
                this.sessionToken = await this.setSessionToken(response.credential);
                this.login(response.credential, this.sessionToken);
                this.setName(await this.getUserDetails(this.sessionToken))
            } catch (error) {
                console.log('There was an issue with the submission.');
            }
        },
        async setSessionToken(cred) {
            try {
                const url = 'https://api.ezyuni.com/auth/login/google-prompt/';
                const data = {
                    idToken: cred
                };
                const headers = {
                    'Content-Type': 'application/json'
                };
                const res = await axios.post(url, data, { headers });
                return res.data.access;
            } catch (error) {
                this.apiError = 'Failed to fetch session token';
                console.error(error);
                throw error;
            }
        },
        async getUserDetails(sessionToken) {
            if (sessionToken) {
                try {
                    const url = 'https://api.ezyuni.com/core/user/me/';
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + sessionToken
                    };
                    const res = await axios.get(url, { headers });
                    return res.data.name;
                } catch (error) {
                    this.apiError = 'Failed to fetch session token';
                    console.error(error);
                    throw error;
                }
            }
        }
    }
};
</script>