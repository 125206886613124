import { createApp } from 'vue'
import EzyUni from './App.vue'
import router from './router/index.js'

import './assets/styles.css'

// Import Bootstrap core
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'


// Import FontAwesome core
import { library } from '@fortawesome/fontawesome-svg-core'

// Import FontAwesome icon component
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Import specific icons
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons';

// Google SignIn
// import vue3GoogleLogin from 'vue3-google-login'
import store from './store/index.js';

// Add icons to the library
library.add(fas, fab, far)

const app = createApp(EzyUni)

// app.use(vue3GoogleLogin, {
//     clientId: '1018713702828-atbnm7u5l8r7v6r7kmurbnplmvtqh6ri.apps.googleusercontent.com'
// })

app.use(router)
router.beforeEach((to, from) => {
    console.log(`Navigating to: ${to.path}`);
    console.log(`Navigating from: ${from.path}`);
});

app.use(store);

app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
