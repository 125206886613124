import { createRouter, createWebHistory } from 'vue-router';
import ChatView from '@/views/ChatView.vue';
// import MainContent from '@/components/layout/MainContent.vue';
import AuthCallback from '@/components/common/AuthCallback.vue';
import NotFound from '@/components/common/NotFound.vue';

const routes = [
    {
      path: '/auth/callback',
      name: 'AuthCallback',
      component: AuthCallback,
    },
    {
        path: '/',
        name: 'ChatView',
        component: ChatView,
    },
    {
      path: '/:catchAll(.*)', // Catch-all route for undefined paths
      name: 'NotFound',
      component: NotFound,
    }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;