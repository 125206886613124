import { createStore } from 'vuex';
import vuexPersist from './vuexPersist';

const store = createStore({
  state: {
    user: null,
    isAuthenticated: false,
    accessToken: null,
    userName: ''
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      state.isAuthenticated = !!user;
    },
    setAccessToken(state, accessToken) {
        state.accessToken = accessToken;
    },
    setUserName(state, userName) {
        state.userName = userName;
    },
    logout(state) {
      state.user = null;
      state.isAuthenticated = false;
      state.accessToken = null;
      state.userName = ''
    },
  },
  actions: {
    login({ commit }, payload) {
        const { user, accessToken } = payload;
        commit('setUser', user);
        commit('setAccessToken', accessToken);
    },
    logout({ commit }) {
        google.accounts.id.revoke('1018713702828-atbnm7u5l8r7v6r7kmurbnplmvtqh6ri.apps.googleusercontent.com', () => {
            console.log('User logged out from Google');
        });
        commit('logout');
    },
    userName({ commit }, payload) {
        const { userName } = payload;
        commit('setUserName', userName);
    }
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    currentUser: (state) => state.user,
    getAccessToken: (state) => state.accessToken,
    getUserName: (state) => state.userName
  },
});

vuexPersist(store);

export default store;
