<template>
    <div class="main-content row justify-content-center p-0">
      <div class="col-12 col-md-6 d-flex flex-column justify-content-center">
        <div v-if="chatMessages.length == 0" class="">
          <div class="header">
            <h1>Hi there, <span class="username">{{ userName }}</span></h1>
            <h2>What would you like to know?</h2>
          </div>
          <div class="light-font lh-sm">
            <span>Use one of the most common prompts</span><br/>
            <span>below or type your own to begin</span>
          </div>
          <div class="prompts row mb-1 mt-2">
            <div 
              v-for="(buttonText, index) in promptButtons" 
              :key="index" 
              class="col-md col-12"
            >
              <PromptButton
                :key="index"
                :text="buttonText"
                @prompt-clicked="setMessage"
              />
            </div>
          </div>
          <button
            class="btn col-6 smaller-font text-start light-font"
            @click="loadPromptButtons">
            <font-awesome-icon 
              :icon="['fas', 'refresh']" 
            /> Refresh Prompts
          </button>
        </div>
        <div v-if="chatMessages.length > 0" class="messages" ref="chatMessages">
          <MessageElement
              v-for="message in chatMessages"
              :key="message.id"
              :sender="message.sender"
              :message="message.text"
              :isBot="message.sender != 'You' ? false : true"
              :createdAt="message.createdAt"
              :class="['message', message.sender !== 'You' ? 'ai-message' : 'my-message']"
            />
        </div>
        <div class="container mt-3 px-0 position-relative">
          <div class="card">
            <div class="card-body pb-0">
              <textarea 
                v-model="userMessage"
                @input="updateCharacterCount"
                class="custom-textarea w-100 fw-semibold smaller-font"
                placeholder="Ask whatever you want..."
                @keydown.enter="handleEnter"
              ></textarea>
              <div class="button-group">
                <div class="row">
                  <div class="col row pl-3">
                    <!-- <button class="btn col-12 col-md-6 smaller-font light-font"><font-awesome-icon :icon="['far', 'square-plus']" :class="['mx-1']"/> Add Attachment</button> -->
                    <button 
                        class="btn col-6 smaller-font light-font text-start"
                        v-if="chatMessages.length > 0"
                        @click="resetWindow">
                      <font-awesome-icon :icon="['fas', 'trash-can']" />
                      Reset
                  </button>
                  </div>
                  <div class="col row justify-content-end">
                    <div class="character-count col-6 col-md-2 d-flex align-items-center">
                      <span class="smaller-font light-font no-word-spacing">{{ charCount }} / 500</span>
                    </div>
                    <button 
                      class="btn col flex-grow-0 text-primary border-0"
                      @click="sendMessage"
                      :disabled="isSendButtonDisabled">
                      <font-awesome-icon :icon="['fas', 'play']" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <transition name="slide-fade" @before-enter="beforeEnter" @enter="enter" @leave="leave">
            <div v-if="isTyping" class="typing-indicator border rounded w-100 justify-content-center py-2">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </transition>
        </div>
      </div>
    </div>
</template>
  
<script>
  import { ref } from 'vue';
  import axios from 'axios';
  import { computed, watch, getCurrentInstance } from 'vue';
  import { useStore } from 'vuex';


  import PromptButton from '@/components/modules/chat/PromptButton.vue'
  import MessageElement from '@/components/modules/chat/MessageElement.vue'
  const questions = [
    "Suggest me some courses in Germany related to IT",
    "What's the best country for studying Science?",
    "How to reach out to Boston's alumni?",
    "Average money needed for studying in Boston?",
    "What are the top career opportunities in the field of Artificial Intelligence?",
    "How can I start a career in data science with a background in engineering?",
    "What are the best universities for studying environmental science in Europe?",
    "Which countries offer the most affordable education for international students?",
    "What are the top scholarships available for studying abroad in the USA?",
    "How can I find financial aid for a master's program in the UK?",
    "What should I know before studying in a foreign country for the first time?",
    "How do I balance academic life with extracurricular activities at university?",
    "What are the key factors to consider when applying to a top-tier university?",
    "How can I improve my chances of getting admitted to a competitive program?",
    "What are the best cities for international students to live in the US?",
    "How can I find affordable housing while studying in Australia?",
    "What are the most promising research areas in renewable energy?",
    "How can I get involved in research projects while studying at university?",
    "How can I make the most out of networking opportunities during my studies?",
    "What are some tips for adjusting to student life in a new country?"
  ];
  export default {
    name: 'MainContent',
    components: {
      PromptButton,
      MessageElement
    },
    setup() {
      const isTyping = ref(false);
  
      const store = useStore();
      const user = computed(() => store.state.user);
      const isAuthenticated = computed(() => store.state.isAuthenticated);
      const accessToken = computed(() => store.state.accessToken);
      const userName = computed(() => store.state.userName);
      const instance = getCurrentInstance();

      function beforeEnter(el) {
        el.style.transform = 'translateY(-100%)';
        el.style.opacity = '0';
      }
  
      function enter(el, done) {
        el.offsetHeight; // Trigger reflow
        el.style.transition = 'transform 0.3s ease, opacity 0.3s ease';
        el.style.transform = 'translateY(0)';
        el.style.opacity = '1';
        done();
      }
  
      function leave(el, done) {
        el.style.transition = 'transform 0.3s ease, opacity 0.3s ease';
        el.style.transform = 'translateY(-100%)';
        el.style.opacity = '0';
        done();
      }

      watch(userName, (newValue) => {
        if (newValue) {
          instance.proxy.loadHistory();
          instance.proxy.initiateScrolling();
        }
      });
      
      return {
        isTyping,
        beforeEnter,
        enter,
        leave,
        isAuthenticated,
        user,
        accessToken,
        userName
      };
    },
    data() {
      return {
        promptButtons: [],
        userMessage: '',
        charCount: 0,
        socket: null,
        chatMessages: [],
        firstMessageReceived: false,
        apiResponse: null,
        apiError: null,
        currentPage: 1,
        loading: false
      };
    },
    watch: {
      '$route.query.loadHistory'(newVal) {
        if (newVal && this.accessToken) {
          this.loadHistory();
        }
      }
    },
    mounted() {
      // this.socket = new WebSocket('wss://echo.websocket.org');
      // this.socket.addEventListener('message', (event) => {
      //   if (!this.firstMessageReceived) {
      //     this.firstMessageReceived = true;
      //     return;
      //   }
      //   setTimeout(() => {
      //     this.isTyping = false;
      //   }, 1000);
      //   console.log("return");
      //   this.chatMessages.push({ id: Date.now(), sender: 'EzyBot', text: event.data });
      //   this.$nextTick(() => {
      //     this.$refs.chatMessages.scrollTop = this.$refs.chatMessages.scrollHeight;
      //   });
      // });
  
      // this.socket.addEventListener('open', () => {
      //   console.log('WebSocket connection established');
      // });
  
      // this.socket.addEventListener('close', () => {
      //   console.log('WebSocket connection closed');
      // });
      this.loadPromptButtons();
      // if (this.$route.query.loadHistory) {
        if (this.accessToken) {
          this.loadHistory();
        }
      // }
      this.initiateScrolling();
    },
    beforeUnmount() {
      const chatContainer = this.$refs.chatMessages;
      if (chatContainer) {
        console.log("de-setting event");
        chatContainer.removeEventListener('scroll', this.handleScroll);
      }
    },
    computed: {
      isSendButtonDisabled() {
        return this.charCount == 0 || this.charCount > 500 || this.accessToken == null;
      }
    },
    methods: {
      initiateScrolling() {
        this.$nextTick(() => {
          setTimeout(() => {
            const chatContainer = this.$refs.chatMessages;
            if (chatContainer) {
              console.log("Setting scroll event listener on chatMessages");
              chatContainer.addEventListener('scroll', this.handleScroll);
            } else {
              console.warn("chatMessages ref is undefined");
            }
          }, 1000);
        });
      },
      updateCharacterCount() {
        this.charCount = this.userMessage.length;
      },
      handleFileUpload(event) {
        const file = event.target.files[0];
        if (file) {
          console.log('File selected:', file.name);
          // Handle file upload here
        }
      },
      sendMessage() {
        if (this.userMessage.trim() !== '') {
          this.isTyping = true
          this.chatMessages.push({ id: Date.now(), sender: 'You', text: this.userMessage, createdAt: new Date().toISOString() });
          // this.socket.send(this.userMessage);
          this.fetchData(this.userMessage);
          this.userMessage = '';
          this.updateCharacterCount();
        }
      },
      handleEnter(event) {
        event.preventDefault();
        if (!this.isSendButtonDisabled) { this.sendMessage(); }
      },
      setMessage(content){
        this.userMessage = content;
        this.updateCharacterCount();
        if (!this.isSendButtonDisabled) { this.sendMessage(); }
      },
      getCurrentTimestamp() {
        const now = new Date();
        return now.toLocaleTimeString();
      },
      resetWindow() {
        this.userMessage = '';
        this.chatMessages = [];
        this.updateCharacterCount();
      },
      getRandomElements(arr, num) {
        const result = [];
        const usedIndices = new Set();
        while (result.length < num && usedIndices.size < arr.length) {
          const index = Math.floor(Math.random() * arr.length);
          if (!usedIndices.has(index)) {
            usedIndices.add(index);
            result.push(arr[index]);
          }
        }
        return result;
      },
      loadPromptButtons() {
        this.promptButtons = this.getRandomElements(questions, 4);
      },
      async fetchData(message) {
        try {
          const url = 'https://api.ezyuni.com/chat/llm/';
          const data = {
            message: message
          };
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          };
          const res = await axios.post(url, data, { headers });
          this.apiResponse = res.data;
          setTimeout(() => {
            this.isTyping = false;
          }, 1000);
          this.chatMessages.push({ id: Date.now(), sender: 'EzyBot', text: res.data.message, createdAt: new Date().toISOString()});
          this.$nextTick(() => {
            this.$refs.chatMessages.scrollTop = this.$refs.chatMessages.scrollHeight;
          });
        } catch (error) {
          this.apiError = 'Failed to fetch data';
          console.error(error);
        }
      },
      async loadHistory() {
        try {
          const url = 'https://api.ezyuni.com/chat/conversation/';
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          };
          const res = await axios.get(url, { headers });
          res.data.results
            .sort((a, b) => a.id - b.id)
            .forEach((messageObj) => {
              this.chatMessages.push({
                id: messageObj.id,
                sender: messageObj.is_user === true ? 'You' : 'EzyBot',
                text: messageObj.message,
                createdAt: new Date(messageObj.created_date).toISOString()
              });
            });
          
          // this.chatMessages.push({ id: Date.now(), sender: 'EzyBot', text: res.data.message, createdAt: new Date().toISOString()});
          this.$nextTick(() => {
            this.$refs.chatMessages.scrollTop = this.$refs.chatMessages.scrollHeight;
          });
        } catch (error) {
          this.apiError = 'Failed to fetch data';
          console.error(error);
        }
      },
      async handleScroll() {
        const chatContainer = this.$refs.chatMessages;
        // Check if the scroll position is at the top
        if (chatContainer.scrollTop === 0 && !this.loading) {
          this.loading = true; // Prevent multiple API calls
          this.currentPage += 1;
          
          const url = "https://api.ezyuni.com/chat/conversation/?page="+this.currentPage;
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          };

          try {
            const response = await axios.get(url, { headers });
            const previousScrollHeight = chatContainer.scrollHeight;

            // Sort the new messages if necessary and prepend them
            const sortedMessages = response.data.results.sort((a, b) => a.id - b.id);
            this.chatMessages.unshift(...sortedMessages.map((messageObj) => ({
              id: messageObj.id,
              sender: messageObj.is_user === true ? 'You' : 'EzyBot',
              text: messageObj.message,
              createdAt: new Date(messageObj.created_date).toISOString()
            })));

            // Optionally, scroll to a specific position to maintain the scroll location
            this.$nextTick(() => {
              const newScrollHeight = chatContainer.scrollHeight;
              chatContainer.scrollTop = newScrollHeight - previousScrollHeight;
            });

            // Check if there are more pages to load
            this.hasMorePages = !!response.data.next;

          } catch (error) {
            console.error('Failed to load more messages:', error);
          } finally {
            this.loading = false;
          }

          if (!this.hasMorePages) {
            this.loading = true;
          }
        }
      }
    }
  }
</script>
  
<style scoped>
    .custom-textarea {
      border: none;
      resize: none;
      outline: none;
    }
    .button-group {
      text-align: right;
      margin-top: 10px;
    }
    .main-content {
      flex: 1;
      padding: 20px;
    }
    .header {
      background: linear-gradient(45deg, #000, #cc44d1, var(--bs-primary));
      -webkit-background-clip: text;
      background-clip: text;
      text-fill-color: transparent;
      -webkit-text-fill-color: transparent;
      display: inline-block;
    }
    .header h1 {
      font-size: 2em;
    }
    .header h2 {
      font-size: 1.5em;
      color: #777;
    }
    .prompts {
      margin: 20px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    .input-area {
      margin-top: 20px;
    }
  
  .messages {
    display: flex;
    flex-direction: column;
    height: 80vh;
    overflow-y: auto;
    padding: 10px;
  }
  
  .message {
    display: flex;
    align-items: center;
    margin: 5px 0;
    padding: 10px;
    border-radius: 10px;
  }
  
  .my-message {
    align-self: flex-end;
    border-radius: 15px 15px 0 15px;
  }
  
  .ai-message {
    background-color: #fff;
    align-self: flex-start;
    border-radius: 7px;
  }
  .ai-logo {
    margin-right: 10px;
  }
  
  .ai-logo img {
    width: 30px;
    height: 30px;
  }
  
  .timestamp {
    font-size: 0.8em;
    color: gray;
    margin-top: 5px;
    text-align: right;
    bottom: 5px;
    right: 10px;
  }
    
  .logo-icon {
    height: 24px;
    width: 24px;
  }
  
  .typing-indicator {
    position: absolute;
    top: -25px;
    background-color: #fff;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
  }
  
  .dot {
    width: 8px;
    height: 8px;
    margin: 0 3px;
    background-color: #999;
    border-radius: 50%;
    animation: blink 1.4s infinite both;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes blink {
    0%, 20%, 50%, 80%, 100% {
      opacity: 0;
    }
    40%, 60% {
      opacity: 1;
    }
  }
  
  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  
  .slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
    transform: translateY(-100%);
    opacity: 0;
  }
</style>  