<template>
    <div>
      <h1>Processing Login...</h1>
    </div>
  </template>
  
  <script>
  export default {
    mounted() {
      this.handleCredentialResponse();
    },
    methods: {
      handleCredentialResponse() {
        // Extract the credential token from the URL hash or query parameters
        const urlParams = new URLSearchParams(window.location.search);
        const idToken = urlParams.get('id_token'); // If using OAuth with ID Token
  
        if (idToken) {
          console.log('ID Token: ', idToken);
          // Here you can send the token to your backend for verification or use it in your app
        } else {
          console.error('No ID token found.');
        }
  
        // Redirect the user or perform further actions
        // e.g., this.$router.push('/home');
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any necessary styles */
  </style>
  